<template>
  <div class="top">
    <section id="header" class="set-margin dash-header">
      <div class="navigation home-container-header">
        <nav class="navbar navbar-light navbar-expand-lg">
          <router-link to="/" target="_self" class="navbar-brand">
            <img id="logo" src="@/assets/logo.svg">
          </router-link>
          <div v-if="this.$store.getters.getisAuth" id="nav-collapse" class="navbar-collapse collapse">
            <ul id="navbar-parent" class="navbar-nav ml-auto align-items-center">
              <div class="Menu Menu--open-downwards Menu--white UserMenu">
                <div @click="this.MenuisDisplay = !this.MenuisDisplay" class="UserMenu__handler" id="Menu_panel">
                  <div class="Avatar__wrapper">
                    <img :src="`${this.$store.getters.getUser.picture}`" class="ImageLoader Avatar">
                  </div>
                  <div class="Nickname">
                    <div class="Nickname__wrapper">
                      <span class="Nickname__span">{{ this.$store.getters.getUser.name }}</span>
                    </div>
                  </div>
                  <span class="Menu__handle Menu__handle--dropdown"></span>
                </div>
                <transition>
                  <div v-show="MenuisDisplay" class="Menu__panel Menu__panel--no-arrow">
                    <ul class="Menu__panel__ul">
                      <li>
                        <router-link to="/logout" class="Menu__panel__ul__a">
                          <img class="Menu__panel__ul__a__icon" src="@/assets/icon/icon-exit.svg"
                            alt="Exit door">ログアウト</router-link>
                      </li>
                    </ul>
                  </div>
                </transition>
              </div>
            </ul>
          </div>
          <div v-else id="nav-collapse" class="navbar-collapse collapse">
            <ul id="navbar-parent" class="navbar-nav ml-auto align-items-center">
              <div class="Menu_guest">
                <ul class="Menu__panel__ul">
                  <router-link to="/signup" class="Menu__panel__ul__a">
                    <img class="Menu__panel__ul__a__icon" src="@/assets/icon/icon-signup.png">
                    新規登録
                  </router-link>
                </ul>
                <ul class="Menu__panel__ul">
                  <router-link to="/login" class="Menu__panel__ul__a">
                    <img class="Menu__panel__ul__a__icon" src="@/assets/icon/icon-login.png">
                    ログイン
                  </router-link>
                </ul>
              </div>
            </ul>
          </div>
        </nav>
      </div>
    </section>
</div>
</template>

<script>
export default {
  data () {
    return {
      MenuisDisplay: false
    }
  },
  mounted () {
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.UserMenu__handler')) {
        this.MenuisDisplay = false
      }
    })
  }
}
</script>

<style lang='scss'>
.top {
  height: 87.8px;
  user-select: none;

  #header {
    padding: 5px 0 12px !important;
  }

  .dash-header {
    width: 100%;

    .navigation {
      z-index: 2;
      position: relative;
      width: 100%;
      margin: 0 auto;
      padding: 1px;

      .navbar {
        display: flex;
        position: relative;
        padding: 0.5rem 1rem;

        .navbar-brand {
          display: flex;
          align-items: center;

          #logo {
            height: auto;
            width: 175px;
          }

          #logo.mobile {
            height: auto;
            width: 60%;
            max-width: 200px;
          }
        }

        .navbar-collapse {
          display: flex;
          flex-basis: auto;
          flex-basis: 100%;
          flex-grow: 1;
          align-items: center;

          .ml-auto {
            margin-left: auto;
          }

          .nav-item {
            font-size: 15px;
            line-height: 20px;
            text-align: left;
            color: #3f3939;

            .nav-link {
              display: block;
              padding: 0.5rem 1rem;
            }

            .top-navbar-link {
              padding-left: 15px;
              padding-right: 15px;
            }
          }

          .UserMenu {

            margin-left: 20px;

            ul {
              list-style-type: none;
            }

            .UserMenu__handler {
              display: flex;
              position: relative;
              grid-gap: calc(20px * 0.5);
              align-items: center;
              height: 100%;
              cursor: pointer;

              .Avatar__wrapper {
                position: relative;
                width: auto;
                height: auto;
                border-radius: inherit;

                .ImageLoader {
                  width: 35px;
                  height: 35px;
                  border-radius: 50%;
                }
              }

              .Nickname {
                max-width: 200px;

                .Nickname__wrapper {
                  display: inline-grid;
                  grid-gap: 5px;
                  grid-gap: calc(20px * 0.25);
                  grid-template-columns: 1fr -webkit-max-content;
                  grid-template-columns: 1fr max-content;
                  align-items: center;

                  .Nickname__span {
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
              }

              .Menu__handle {
                display: flex;
                box-sizing: content-box;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                transition: transform .4s;
                outline: none;
                cursor: pointer;
                will-change: transform;
              }

              .Menu__handle:before {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                background-size: 10px px;
              }

              .Menu__handle:before {
                content: "";
                display: block;
                width: 6px;
                height: 19px;
                background-image: url(@/assets/icon/icon-timeline-item-handle.svg);
                background-repeat: no-repeat;
                background-position: 50%;
                background-size: contain;
              }
            }

            .Menu__panel {
              position: absolute;
              z-index: 9000;
              bottom: -5px;
              transform: none;
              opacity: 1;
              color: #212121;
              pointer-events: auto;
              will-change: opacity, transform;
              top: calc(100% + 10px);
              right: 25px;
              color: #888;

              &.v-enter-active {
                animation: fadeIn .4s ease 0s 1 normal backwards;
              }

              @keyframes fadeIn {
                from {
                  opacity: 0;
                  transform: translateY(-15px);
                }

                to {
                  opacity: 1;
                  transform: translateY(0);
                }
              }

              .Menu__panel__ul {
                position: relative;
                z-index: 2000;
                padding: 6px 0;
                padding: calc(20px * 0.3) 0;
                border-radius: 5px;
                background-color: #f9f9f9;
                box-shadow: 0 1px 4px 0 rgb(0 0 0 / 29%);
                padding: 0;

                a {
                  color: currentcolor;
                  text-decoration: none;
                  cursor: pointer;
                }

                .Menu__panel__li__profile {
                  grid-gap: 16px;
                  gap: 16px;

                  h4 {
                    color: #212121;
                    font-weight: 700;
                  }

                  .Avatar__wrapper {
                    position: relative;
                    width: 56px;
                    min-width: 56px;
                    height: 56px;
                    border-radius: inherit;

                    .ImageLoader {
                      background-color: #f4f4f4;
                      object-fit: cover;
                      width: 100%;
                      height: 100%;
                    }

                    .Avatar {
                      border-radius: 50%;
                    }
                  }
                }

                .Menu__panel__ul__a {
                  display: flex;
                  align-items: center;
                  grid-gap: 8px;
                  gap: 8px;
                  padding: calc(20px * 0.8);
                  min-width: 170px;
                  transition: border-color .4s, background-color .4s;
                  text-align: left;
                  white-space: nowrap;
                  will-change: border-color, background-color;

                  .Menu__panel__ul__a__icon {
                    width: 18px;
                    height: 18px;
                  }
                }
              }

              .Menu__panel__ul>:not(:last-child) {
                border-bottom: 1px solid #bcbcbc;
              }
            }
          }
          .Menu_guest {
            display: flex;

            a {
              color: rgba(0,0,0,.5);
              font-weight: bold;
              text-decoration: none;
              cursor: pointer;
            }

            .Menu__panel__ul__a {
              display: flex;
              align-items: center;
              grid-gap: 13px;
              gap: 13px;
              min-width: 130px;
              white-space: nowrap;

              .Menu__panel__ul__a__icon {
                width: 25px;
                height: 25px;
              }
            }
          }
        }
      }
    }
  }

  .align-items-center {
    align-items: center;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  a {
    text-decoration: none;
  }

  .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .5);
  }

}
</style>
