import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createAuth0 } from '@auth0/auth0-vue'
import authConfig from '../auth_config.json'

createApp(App).use(store).use(router).use(VueAxios, axios)
  .use(
    createAuth0({
      domain: authConfig.domain,
      clientId: authConfig.client_id,
      audience: authConfig.audience,
      authorizationParams: {
        redirect_uri: window.location.origin
      }
    }))
  .mount('#app')
