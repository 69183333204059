<template>
  <div class="home">
    <div v-if="HeadError" class="head_error">
      <div class="error_container">
        <p v-if="this.HeadErrorDescription == 43">メールアドレスが確認されていません。<br>認証メールを確認後にログインをしてください。</p>
        <p v-else>原因不明のエラーが発生しています。<br>お手数をおかけいたしますが、ホームページからお問い合わせください。</p>
      </div>
    </div>
    <h2 class="main_title">
      <div>有料プランなら<br>
        <span>maiderがもっと便利で快適に</span>
      </div>
    </h2>
    <div class="main">
      <div class="pattern-c">
        <div class="section_payment">
          <div class="payment_method_contents">
            <div class="plan_container">
              <div class="plan_select">
                <div class="section_header_title">
                  <h2>1. プランの選択</h2>
                </div>
                <div class="plan_method_container">
                  <div class="plan_method_left small_container">
                    <div class="title">
                      <span class="bg">
                        <span class="type_tag">お手軽プラン</span>
                      </span>
                    </div>
                    <div v-on:click="select_plan('price_1McTj1Lqqx2wL7ra1vgxXBcr', 'price_1McTklLqqx2wL7raIVGCcGcG', 'small_monthly', 'お手軽プラン', '980', 'monthly')" class="inner_box">
                      <h3 class="app-plan-heading">個人販売者向け
                        <span class="ECM_RadioInput-DummyInput" :class="{'checked':  this.SelectPlan == 'small_monthly' }"></span>
                        <span class="app-plan-name">スモールビジネス</span>
                      </h3>
                      <p class="detail">個人で販売されている方や、お試しを希望される方に向けたプラン</p>
                      <div class="app-element-subscript">
                        <dl class="app-plan-prices">
                          <dd class="monthly-amount">￥980
                            <span class="app-plan-price-unit">/月額</span>
                          </dd>
                        </dl>
                      </div>
                      <div class="sd_list">
                        <ul>
                          <li>
                            <p>受注管理の全機能</p>
                          </li>
                          <li>
                            <p>バナー広告付き</p>
                          </li>
                          <li>
                            <p>従業員の追加なし</p>
                          </li>
                          <li>
                            <p>1ヶ月に20件まで</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="plan_method_center standard_container">
                    <div class="title">
                      <span class="bg">
                        <span class="type_tag">スタンダードプラン</span>
                      </span>
                    </div>
                    <div v-on:click="select_plan('price_1McTjJLqqx2wL7raT5WB7wsK', 'price_1McTl4Lqqx2wL7raI5cOwfHO', 'standard_monthly', 'スタンダードプラン', '1,980', 'monthly')" class="inner_box">
                      <h3 class="app-plan-heading">小規模事業者向け
                        <span class="ECM_RadioInput-DummyInput" :class="{'checked':  this.SelectPlan == 'standard_monthly' }"></span>
                        <span class="app-plan-name">サブビジネス</span>
                      </h3>
                      <p class="detail">数名で運営されていて、最低限の業務の効率化を図り合い方に向けたプラン</p>
                      <div class="app-element-subscript">
                        <dl class="app-plan-prices">
                          <dd class="monthly-amount">￥1,980
                            <span class="app-plan-price-unit">/月額</span>
                          </dd>
                        </dl>
                      </div>
                      <div class="sd_list">
                        <ul>
                          <li>
                            <p>お手軽プランの全機能</p>
                          </li>
                          <li>
                            <p>バナー広告なし</p>
                          </li>
                          <li>
                            <p>従業員を5人まで追加</p>
                          </li>
                          <li>
                            <p>1ヶ月に100件まで</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="plan_method_right premium_container">
                    <div class="title">
                      <span class="bg">
                        <span class="type_tag">プレミアムプラン</span>
                      </span>
                    </div>
                    <div v-on:click="select_plan('price_1McTjdLqqx2wL7raBSP3N6IJ', 'price_1McTlLLqqx2wL7raXXKqTwrD', 'premium_monthly', 'プレミアムプラン', '4,980', 'monthly')" class="inner_box">
                      <h3 class="app-plan-heading">法人事業者向け
                        <span class="ECM_RadioInput-DummyInput" :class="{'checked':  this.SelectPlan == 'premium_monthly' }"></span>
                        <span class="app-plan-name">メインビジネス</span>
                      </h3>
                      <p class="detail">法人で主力ビジネスとして、運営されている方に向けた豪華プラン</p>
                      <div class="app-element-subscript">
                        <dl class="app-plan-prices">
                          <dd class="monthly-amount">￥4,980
                            <span class="app-plan-price-unit">/月額</span>
                          </dd>
                        </dl>
                      </div>
                      <div class="sd_list">
                        <ul>
                          <li>
                            <p>スタンダードプランの全機能</p>
                          </li>
                          <li>
                            <p>優先サポート</p>
                          </li>
                          <li>
                            <p>制限なし</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="plan_method_container">
                  <div class="plan_method_left small_container">
                    <div class="title year">
                      <span class="bg">
                        <span class="type_tag">年額プラン</span>
                      </span>
                    </div>
                    <div v-on:click="select_plan('price_1Mdl9XLqqx2wL7raMgZYnq9d', 'price_1MdlAVLqqx2wL7ragY3EzHpi', 'small_year', 'お手軽プラン', '9,980', 'year')" class="inner_box">
                      <h3 class="app-plan-heading">個人販売者向け
                        <span class="ECM_RadioInput-DummyInput" :class="{'checked':  this.SelectPlan == 'small_year' }"></span>
                        <span class="app-plan-name">スモールビジネス</span>
                      </h3>
                      <p class="detail">個人で販売されている方や、お試しを希望される方に向けたプラン</p>
                      <div class="app-element-subscript">
                        <dl class="app-plan-prices">
                          <dd class="monthly-amount">￥9,980
                            <span class="app-plan-price-unit">/年額</span>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div class="plan_method_center standard_container">
                    <div class="title year">
                      <span class="bg">
                        <span class="type_tag">年額プラン</span>
                      </span>
                    </div>
                    <div v-on:click="select_plan('price_1MdlBpLqqx2wL7rab1imIx6T', 'price_1MdlCoLqqx2wL7raCuTYe4Zl', 'standard_year', 'スタンダードプラン', '20,180', 'year')" class="inner_box">
                      <h3 class="app-plan-heading">小規模事業者向け
                        <span class="ECM_RadioInput-DummyInput" :class="{'checked':  this.SelectPlan == 'standard_year' }"></span>
                        <span class="app-plan-name">サブビジネス</span>
                      </h3>
                      <p class="detail">数名で運営されていて、最低限の業務の効率化を図り合い方に向けたプラン</p>
                      <div class="app-element-subscript">
                        <dl class="app-plan-prices">
                          <dd class="monthly-amount">￥20,180
                            <span class="app-plan-price-unit">/年額</span>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div class="plan_method_right premium_container">
                    <div class="title year">
                      <span class="bg">
                        <span class="type_tag">年額プラン</span>
                      </span>
                    </div>
                    <div v-on:click="select_plan('price_1MdlEFLqqx2wL7rax8pwqcda', 'price_1MdlEULqqx2wL7rapADoZmPO', 'premium_year', 'プレミアムプラン', '50,780', 'year')" class="inner_box">
                      <h3 class="app-plan-heading">法人事業者向け
                        <span class="ECM_RadioInput-DummyInput" :class="{'checked':  this.SelectPlan == 'premium_year' }"></span>
                        <span class="app-plan-name">メインビジネス</span>
                      </h3>
                      <p class="detail">法人で主力ビジネスとして、運営されている方に向けた豪華プラン</p>
                      <div class="app-element-subscript">
                        <dl class="app-plan-prices">
                          <dd class="monthly-amount">￥50,780
                            <span class="app-plan-price-unit">/年額</span>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="payment_container">
              <div class="payment_select">
                <div class="section_header_title ab_tokusho_b">
                  <h2>2. お支払い方法の選択</h2>
                </div>
                <div class="payment_method_container">
                  <div class="payment_method_left">
                    <div class="category monthly">
                      <div class="title">
                        <span class="bg">
                          <span class="type_tag">自動更新</span>
                        </span>
                      </div>
                      <div class="select_container">
                        <ul>
                          <li>
                            <label v-on:click="select_payment('credit', 'クレジットカード', false)" for="btn_subs_cc_monthly" class="radio_input">
                              <span class="ECM_RadioInput-DummyInput" :class="{'checked':  this.SelectPayment == 'credit' }"></span>
                              <div class="name">
                                <div class="payment_method_name">クレジットカード</div>
                                <div class="payment_method_logo">
                                  <div class="credit_card_icon">
                                    <ul class="services card">
                                      <li>
                                        <img
                                          src="@/assets/brand_icon/visa.png"
                                          class="brand-icon">
                                      </li>
                                      <li>
                                        <img
                                          src="@/assets/brand_icon/mastercard.png"
                                          class="brand-icon">
                                      </li>
                                      <li>
                                        <img
                                          src="@/assets/brand_icon/jcb.png"
                                          class="brand-icon">
                                      </li>
                                      <li>
                                        <img
                                          src="@/assets/brand_icon/amex.png"
                                          class="brand-icon">
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </label>
                          </li>
                          <li style="display: none;">
                            <label v-on:click="select_payment('mobile', 'キャリア決済', false)" class="btn_mobile_monthly radio_input">
                              <span class="ECM_RadioInput-DummyInput" :class="{'checked':  this.SelectPayment == 'mobile' }"></span>
                              <div class="name">
                                <div class="payment_method_name">キャリア決済</div>
                                <div class="payment_method_logo">
                                  <div class="mobile_payment_icon">
                                    <ul class="services mobile">
                                      <li>
                                        <img src="https://www.epsilon.jp/service/images/logo_docomo.svg" height="14"
                                          class="brand-icon">
                                      </li>
                                      <li>
                                        <img src="https://www.epsilon.jp/service/images/logo_au.svg" height="11"
                                          class="brand-icon">
                                      </li>
                                      <li>
                                        <img src="https://www.epsilon.jp/service/images/logo_softbank.svg" height="11"
                                          class="brand-icon">
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </label>
                          </li>
                          <li style="display: none;">
                            <label v-on:click="select_payment('paypal', 'PayPal', false)" class="btn_paypal_monthly radio_input">
                              <span class="ECM_RadioInput-DummyInput" :class="{'checked':  this.SelectPayment == 'paypal' }"></span>
                              <div class="name">
                                <div class="payment_method_name">PayPal</div>
                                <div class="payment_method_logo">
                                  <div class="services paypal">
                                    <!-- PayPal Logo -->
                                    <table border="0" cellpadding="10" cellspacing="0" align="center">
                                      <tr>
                                        <td align="center">
                                          <img
                                            src="https://www.paypalobjects.com/digitalassets/c/website/marketing/apac/jp/developer/203x80_a.png"
                                            alt="ペイパル ｜VISA, Mastercard, JCB, American Express, Union Pay, 銀行">
                                        </td>
                                      </tr>
                                    </table>
                                    <!-- PayPal Logo -->
                                  </div>
                                </div>
                              </div>
                            </label>
                          </li>
                          <li style="display: none;">
                            <label v-on:click="select_payment('bank', '口座振替', false)" class="btn_bank_monthly radio_input">
                              <span class="ECM_RadioInput-DummyInput" :class="{'checked':  this.SelectPayment == 'bank' }"></span>
                              <div class="name">
                                <div class="payment_method_name">口座振替</div>
                              </div>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div class="description">※毎月、更新日に自動でお支払いされます。</div>
                    </div>
                  </div>
                  <div class="payment_method_right">
                    <div class="category yearly">
                      <div class="title">
                        <span class="bg">
                          <span class="type_tag">都度払い</span>
                        </span>
                      </div>
                      <div class="select_container">
                        <ul>
                          <li>
                            <label v-on:click="select_payment('konbini', 'コンビニ決済', true)" class="btn_konbini_monthly radio_input">
                              <span class="ECM_RadioInput-DummyInput" :class="{'checked':  this.SelectPayment == 'konbini' }"></span>
                              <div class="name">
                                <div class="payment_method_name">コンビニ決済</div>
                                <div class="payment_method_logo">
                                  <div class="konbini_payment_icon">
                                    <ul class="services konbini">
                                      <li>
                                        <img src="@/assets/icon/fm_logo01.gif" height="17" class="brand-icon">
                                      </li>
                                      <li>
                                        <img src="@/assets/icon/lw_logo01.gif" height="9.5" class="brand-icon">
                                      </li>
                                      <li>
                                        <img src="@/assets/icon/mini_logo01.gif" height="19" class="brand-icon">
                                      </li>
                                      <li>
                                        <img src="@/assets/icon/sm_logo01.gif" height="17" class="brand-icon">
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </label>
                          </li>
                          <li style="display: none;">
                            <label v-on:click="select_payment('paypay', 'PayPay', true)" class="btn_paypay_monthly radio_input">
                              <span class="ECM_RadioInput-DummyInput" :class="{'checked':  this.SelectPayment == 'paypay' }"></span>
                              <div class="name">
                                <div class="payment_method_name">PayPay</div>
                                <div class="payment_method_logo">
                                  <div class="services paypay">
                                    <img src="@/assets/icon/paypay_logo_1.jpg" height="28" class="brand-icon">
                                  </div>
                                </div>
                              </div>
                            </label>
                          </li>
                          <li style="display: none;">
                            <label v-on:click="select_payment('linepay', 'LINE Pay', true)" class="btn_linepay_monthly radio_input">
                              <span class="ECM_RadioInput-DummyInput" :class="{'checked':  this.SelectPayment == 'linepay' }"></span>
                              <div class="name">
                                <div class="payment_method_name">LINE Pay</div>
                                <div class="payment_method_logo">
                                  <div class="services linepay">
                                    <img src="@/assets/icon/LINE-Pay(h)_W119_logo01.png" height="17" class="brand-icon">
                                  </div>
                                </div>
                              </div>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div class="description">※毎月、決まった期限までにお支払いしていただく必要がございます。</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="payment_fix ab_tokusho_a">
                <div class="section_header_title">
                  <h2>3. 登録画面へ</h2>
                </div>
                <div class="payment_fix_container" v-bind:class="SelectPlan" data-payment-button="btn_subs_cc_monthly">
                  <div class="detail">
                    <div class="premium">{{ this.SelectPlanName }}</div>
                    <div class="payment_type">お支払い方法：<span id="payment_type">{{ this.SelectPaymentName }}</span></div>
                    <div class="payment_price">
                      <div class="price_box_left">¥{{ this.SelectPlanPrice }}</div>
                      <div class="price_box_right">
                        <span v-if="this.SelectPaymentStepOnce === false && this.SelectPlanStep === 'monthly'" class="premium_term">/月額<br></span>
                        <span v-if="this.SelectPaymentStepOnce === false && this.SelectPlanStep === 'year'" class="premium_term">/年額<br></span>
                        <span v-if="this.SelectPaymentStepOnce === false" class="payment_step">（自動更新）</span>
                        <span v-if="this.SelectPaymentStepOnce === true && this.SelectPlanStep === 'monthly'" class="premium_term">/1ヶ月<br></span>
                        <span v-if="this.SelectPaymentStepOnce === true && this.SelectPlanStep === 'year'" class="premium_term">/1年<br></span>
                        <span v-if="this.SelectPaymentStepOnce === true" class="payment_step">（手動更新）</span>
                      </div>
                    </div>
                    <p class="notice">
                      <a href="https://lp-maider.studio.site/terms" target="_blank">利用規約</a>を必ずお読みください。<br>
                      会員登録の完了をもって規約に同意したものとします。
                    </p>
                    <p class="btn_fix">
                      <button v-if="!this.payment_in_progress" @click="Payment()">購入手続きに進む</button>
                      <button v-else disabled>読み込み中...</button>
                    </p>
                    <ul class="description">
                      <li>お支払い後のキャンセルはできません。</li>
                      <li>支払時期等の詳細は<a href="https://lp-maider.studio.site/tokusho" target="_blank">こちら</a>をご確認ください。
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card_payment_wrapper" :class='{active: CreditPaymentOpen}'>
    <div class="card-form">
      <div class="card-form__inner">
        <form id="payment-form">
          <div id="payment-element">
            <!-- Elements will create form elements here -->
          </div>
          <div id="error-message" class="card-form__error">
            <!-- Display error message to your customers here -->
          </div>
          <button id="submit" class="card-form__button">
            確認画面に進む
          </button>
          <button class="card-form__button back" @click="PaymentCancel()">
            前の画面に戻る
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      HeadError: false,
      HeadErrorDescription: 0,
      SelectPlan: 'small_monthly',
      SelectPlanId: '',
      SelectPlanIdOnce: '',
      SelectPlanName: 'お手軽プラン',
      SelectPlanPrice: '980',
      SelectPlanStep: 'monthly',
      SelectPayment: 'credit',
      SelectPaymentName: 'クレジットカード',
      SelectPaymentStepOnce: false,
      // 支払いの準備処理中（支払いボタンの無効化）
      payment_in_progress: false,
      // クレジット決済の場合に決済ウィンドウを表示
      CreditPaymentOpen: false,
      StripeSubscriptionCreate_res: null
    }
  },
  mounted () {
    const plan = this.$route.query.selectplan
    if (plan === 'small_monthly') {
      this.select_plan('price_1McTj1Lqqx2wL7ra1vgxXBcr', 'price_1McTklLqqx2wL7raIVGCcGcG', 'small_monthly', 'お手軽プラン', '980', 'monthly')
    } else if (plan === 'standard_monthly') {
      this.select_plan('price_1McTjJLqqx2wL7raT5WB7wsK', 'price_1McTl4Lqqx2wL7raI5cOwfHO', 'standard_monthly', 'スタンダードプラン', '1,980', 'monthly')
    } else if (plan === 'premium_monthly') {
      this.select_plan('price_1McTjdLqqx2wL7raBSP3N6IJ', 'price_1McTlLLqqx2wL7raXXKqTwrD', 'premium_monthly', 'プレミアムプラン', '4,980', 'monthly')
    } else if (plan === 'small_year') {
      this.select_plan('price_1Mdl9XLqqx2wL7raMgZYnq9d', 'price_1MdlAVLqqx2wL7ragY3EzHpi', 'small_year', 'お手軽プラン', '9,980', 'year')
    } else if (plan === 'standard_year') {
      this.select_plan('price_1MdlBpLqqx2wL7rab1imIx6T', 'price_1MdlCoLqqx2wL7raCuTYe4Zl', 'standard_year', 'スタンダードプラン', '20,180', 'year')
    } else if (plan === 'premium_year') {
      this.select_plan('price_1MdlEFLqqx2wL7rax8pwqcda', 'price_1MdlEULqqx2wL7rapADoZmPO', 'premium_year', 'プレミアムプラン', '50,780', 'year')
    } else {
      this.select_plan('price_1McTj1Lqqx2wL7ra1vgxXBcr', 'price_1McTklLqqx2wL7raIVGCcGcG', 'small_monthly', 'お手軽プラン', '980', 'monthly')
    }

    const urlQuery = this.getUrlQueries()
    if (urlQuery.error === 'access_denied') {
      this.HeadError = true
      // メールアドレスが認証されていない時
      if (urlQuery.error_description.match(/[0043]/)) {
        this.HeadErrorDescription = 43
      }
    }
  },
  methods: {
    getUrlQueries () {
      const queryStr = window.location.search.slice(1)
      const queries = {}

      // クエリがない場合は空のオブジェクトを返す
      if (!queryStr) {
        return queries
      }

      // クエリ文字列を & で分割して処理
      queryStr.split('&').forEach(function (queryStr) {
        // = で分割してkey,valueをオブジェクトに格納
        const queryArr = queryStr.split('=')
        queries[queryArr[0]] = decodeURIComponent(queryArr[1])
      })

      return queries
    },
    select_plan (planId, planIdOnce, plan, planName, planPrice, planStep) {
      this.SelectPlanId = planId
      this.SelectPlanIdOnce = planIdOnce
      this.SelectPlan = plan
      this.SelectPlanName = planName
      this.SelectPlanPrice = planPrice
      this.SelectPlanStep = planStep
    },
    select_payment (payment, paymentName, paymentStepOnce) {
      this.SelectPayment = payment
      this.SelectPaymentName = paymentName
      this.SelectPaymentStepOnce = paymentStepOnce
    },
    async Payment () {
      this.payment_in_progress = true
      if (this.SelectPayment === 'credit' || this.SelectPayment === 'konbini') {
        if (!this.SubscriptionclientSecret) {
          let priceid = ''
          if (this.paymentStepOnce) {
            priceid = this.SelectPlanIdOnce
          } else {
            priceid = this.SelectPlanId
          }

          const data = {
            priceId: priceid,
            payment: this.SelectPayment,
            customerId: this.$store.getters.getUser['https://maider.jp/customerID']
          }
          await this.axios.post('https://api-001.maider.jp/stripe/create/subscription', data, { headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.$store.getters.getAuthToken } })
            .then(response => {
              this.StripeSubscriptionCreate_res = response.data.response
            })
            .catch(err => {
              console.log(err)
            })
        }

        if (this.SelectPayment === 'credit') {
          const stripe = window.Stripe('pk_test_51MZrEALqqx2wL7rajOlbpou336UgBhBQglrrVONqkLqk6QFwJ4tuUoyIfIcRNPHQ0jIeUJ0Ac1RZJOs46poVf9HN004PUoAmFW')
          const options = {
            clientSecret: this.StripeSubscriptionCreate_res
          }
          const elements = stripe.elements(options)
          this.card = elements.create('payment')
          this.card.mount('#payment-element')

          const form = document.getElementById('payment-form')

          form.addEventListener('submit', async (event) => {
            event.preventDefault()

            const { error } = await stripe.confirmPayment({
              elements,
              confirmParams: {
                return_url: 'https://localhost:8081/complete/'
              }
            })

            if (error) {
              const messageContainer = document.querySelector('#error-message')
              messageContainer.textContent = error.message
            } else {
              // Your customer will be redirected to your `return_url`. For some payment
              // methods like iDEAL, your customer will be redirected to an intermediate
              // site first to authorize the payment, then redirected to the `return_url`.
            }
          })
          this.CreditPaymentOpen = true
        } else if (this.SelectPayment === 'konbini') {
          location.href = this.StripeSubscriptionCreate_res
        }
      } else {
        alert('そのお支払い方法は現在対応しておりません。')
      }
      this.payment_in_progress = false
    },
    PaymentCancel () {
      this.CreditPaymentOpen = false
    }
  }
}
</script>

<style lang='scss'>
.home {
  li {
    list-style: none;
  }

  h2.main_title {
    margin: 28px 0 20px;
    font-size: 36px;
    line-height: 1.4;
    text-align: center;

    div {
      font-size: 24px;
      transform: rotate(0.03deg);

      span {
        font-size: 36px;
      }
    }
  }

  .head_error {
    width: 100%;
    margin: 10px 0 40px;
    text-align: center;

    .error_container {
      width: fit-content;
      margin: 0 auto;
      padding: 10px 10%;
      border: 2px solid #FF0000;

      p {
        white-space: pre-line;
        color: #FF0000;
        font-size: 15.5px;
        font-weight: bold;
      }
    }
  }

  .main {
    width: 100%;
    margin: 0 auto;

    .pattern-c {
      transform: rotate(0.03deg);

      .section_payment {
        margin: 0 auto;
        width: 100%;
        padding-bottom: 64px;

        .payment_method_contents {
          max-width: 1200px;
          width: 80%;
          margin: 0 auto;

          .section_header_title {
            text-align: center;
            background-color: #e5e5e5;
            background: none;
            line-height: 24px;
            margin-bottom: 28px;
            padding-top: 56px;

            h2 {
              display: flex;
              align-items: center;
              font-weight: 700;
              font-size: 18px;
              color: #252525;
            }

            h2:before {
              margin-right: 1rem;
            }

            h2:after {
              margin-left: 1rem;
            }

            h2:before,
            h2:after {
              border-top: 1px solid #ccc;
              content: "";
              flex-grow: 1;
            }
          }

          .plan_container {

            .plan_select {
              width: 100%;

              .plan_method_container {
                display: inline-flex;
                width: 100%;
              }

              .premium_container {
                border-top: 4px solid #d9a300!important;

                .app-plan-name {
                  color: #d9a300;
                }
              }

              .standard_container {
                border-top: 4px solid #1E90FF!important;

                .app-plan-name {
                  color: #1E90FF;
                }
              }

              .small_container {
                border-top: 4px solid #939393!important;

                .app-plan-name {
                  color: #939393;
                }
              }

              .plan_method_left,
              .plan_method_center {
                border: 1px dashed #ccc;
                width: 33%;
                margin-right: 16px;
              }

              .plan_method_right {
                border: 1px dashed #ccc;
                width: 33%;
              }

              .title {
                text-align: center;
                margin-top: -8px;
                margin-bottom: 30px;

                span.bg {
                  background: #FFFFFF;
                }

                span.type_tag {
                  background: #252525;
                  color: #fff;
                  padding: 5px 14px;
                  border-radius: 20px;
                  font-weight: bold;
                  font-size: 14px;
                  position: relative;
                  top: -5px;
                }
              }

              .title.year {
                margin-top: 25px;
              }

              .inner_box {
                padding: 0 32px 40px;
                text-align: center;
                cursor: pointer;

                .app-plan-heading {

                  .ECM_RadioInput-DummyInput {
                    position: relative;
                    top: -27px;
                    left: 0;
                    display: block;
                    box-sizing: border-box;
                    width: 25px;
                    height: 25px;
                    margin: 0 16px 0 32px;
                    border-radius: 50%;
                    background: #fff;
                    transition: transform .15s linear;
                    border: 1px solid #ccc;
                  }

                  .ECM_RadioInput-DummyInput.checked {
                    background: #0180FF;
                    border: 1px solid #F9F9F9;
                    box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%), 0 0 0 1px #0180ff inset, 0 0 0 5px #fff inset;
                  }

                  .app-plan-name {
                    display: block;
                    margin: -20px 0 30px;
                    font-size: 16px;
                    font-weight: 700;
                  }
                }

                .detail {
                  margin-bottom: 32px
                }

                .app-element-subscript {
                  margin-bottom: 25px;

                  .monthly-amount {
                    font-size: 32px;
                    font-weight: 700;

                    .app-plan-price-unit {
                      font-size: 16px;
                    }
                  }
                }

                .sd_list {

                  p {
                    position: relative;
                    display: inline;
                    line-height: 2;
                  }

                  p::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: .35em;
                    left: -1.6em;
                    width: 10px;
                    height: 5px;
                    border-left: 3px solid #9c9c9c;
                    border-bottom: 3px solid #9c9c9c;
                    transform: rotate(-45deg);
                  }
                }
              }
            }
          }

          .payment_container {
            display: inline-flex;

            .payment_select {
              width: 70%;
              margin-right: 20px;

              .payment_method_container {
                display: inline-flex;
                width: 100%;

                .payment_method_left {
                  width: 50%;
                  margin-right: 16px;
                }

                .payment_method_right {
                  width: 50%;
                }

                .category {
                  border: 1px dashed #ccc;

                  ul {
                    margin-top: 32px;
                  }

                  .description {
                    margin: 0 auto 15px;
                    width: 90%;
                    font-weight: 400;
                    font-size: 13px;
                    color: #999999;
                    position: relative;
                  }

                  .title {
                    text-align: center;
                    font-size: 18px;
                    font-weight: bold;
                    margin-top: -8px;
                    margin-bottom: 36px;

                    span.bg {
                      background: #FFFFFF;
                      padding: 0 10px;
                    }

                    span.type_tag {
                      background: #252525;
                      color: #fff;
                      padding: 4px 16px;
                      border-radius: 20px;
                      font-weight: bold;
                      font-size: 14px;
                      position: relative;
                      top: -5px;
                    }
                  }

                  .btn_paypal_monthly {
                    .name {
                      height: auto !important;
                    }

                    .ECM_RadioInput-DummyInput {
                      top: -55px !important;
                    }
                  }

                  .radio_input {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .ECM_RadioInput-DummyInput {
                      position: relative;
                      top: -24px;
                      left: 0;
                      display: block;
                      box-sizing: border-box;
                      width: 20px;
                      height: 20px;
                      margin: 0 16px 0 32px;
                      border-radius: 50%;
                      background: #fff;
                      transition: transform .15s linear;
                      border: 1px solid #ccc;
                    }

                    .ECM_RadioInput-DummyInput.checked {
                      background: #0180FF;
                      border: 1px solid #F9F9F9;
                      box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%), 0 0 0 1px #0180ff inset, 0 0 0 5px #fff inset;
                    }

                    .name {
                      display: table-cell;
                      height: 78px;

                      .payment_method_name {
                        margin: 4px 0 0;
                        font-size: 16px;
                        font-weight: 700;
                      }

                      .payment_method_logo {
                        width: 280px;
                        margin: 5px 0 28px;

                        .paypal.services {
                          height: 80px;
                        }

                        .services {
                          width: 100%;
                          height: 25px;
                          margin: 0;
                        }

                        .credit_card_icon {
                          ul.card {
                            display: flex;
                            align-items: center;
                          }

                          .brand-icon {
                            width: 33px;
                            max-height: 25px;
                            margin-right: 4px;
                            vertical-align: middle;
                            object-fit: contain;
                          }
                        }

                        .mobile_payment_icon {
                          ul.mobile {
                            display: flex;
                            align-items: center;
                          }

                          .brand-icon {
                            width: auto;
                            margin-right: 10px;
                            vertical-align: middle;
                          }
                        }

                        .konbini_payment_icon {
                          ul.konbini {
                            display: flex;
                            align-items: center;
                          }

                          .brand-icon {
                            width: auto;
                            margin-right: 10px;
                            vertical-align: middle;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .payment_fix {
            width: 30%;

            .payment_fix_container.small_monthly,
            .payment_fix_container.small_year {
              border-top: 8px solid #939393;

              .btn_fix button {
                  background: linear-gradient(90.18deg, #939393 0.32%, #b7b7b7 99.68%);
                }
            }

            .payment_fix_container.standard_monthly,
            .payment_fix_container.standard_year {
              border-top: 8px solid #1E90FF;

              .btn_fix button {
                  background: #1E90FF;
                }
            }

            .payment_fix_container.premium_monthly,
            .payment_fix_container.premium_year {
              border-top: 8px solid #d9a300;

              .btn_fix button {
                  background: linear-gradient(90.18deg, rgb(214, 183, 124) 0.32%, rgb(216, 203, 172) 99.68%);
                }
            }

            .payment_fix_container {
              width: 100%;
              background: #fff;
              box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 10%);

              .detail {
                padding: 32px 32px 24px 32px;
                font-size: 18px;

                .premium {
                  width: 162px;
                  height: 24px;
                  margin-bottom: 4px;
                  font-weight: 800;
                  font-size: 18px;
                  line-height: 24px;
                }

                .payment_type {
                  margin-bottom: 24px;
                  font-weight: 800;
                  font-size: 14px;
                  line-height: 18px;
                }

                .payment_price {
                  display: flex;
                  margin-bottom: 16px;
                  font-weight: 800;

                  .price_box_left {
                    margin-right: 8px;
                    font-size: 42px;
                  }

                  .price_box_right {
                    line-height: 15px;
                    margin-top: 30px;

                    .premium_term {
                      font-size: 15px;
                    }

                    .payment_step {
                      font-size: 10px;
                    }
                  }
                }

                .notice {
                  margin-bottom: 16px;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 18px;
                  color: #999999;
                  margin-left: 8px;

                  a {
                    color: #1374E5;
                    text-decoration: none;
                  }
                }

                .btn_fix button {
                  position: relative;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  height: 64px;
                  border: 1px solid #FFFFFF;
                  border-radius: 4px;
                  color: #fff;
                  font-weight: 800;
                  font-size: 20px;
                  cursor: pointer;
                }

                .description {
                  margin-left: 14px;
                  margin-top: 16px;
                  font-size: 12px;
                  line-height: 130%;
                  text-align: left;

                  a {
                    color: #1374E5;
                    text-decoration: none;
                  }

                  li:not(:last-child) {
                    margin-bottom: 4px;
                  }

                  li {
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 160%;
                    color: #999999;
                    list-style: none;
                    position: relative;
                  }

                  li:before {
                    content: '';
                    width: 3px;
                    height: 3px;
                    border-radius: 100%;
                    background: #999999;
                    position: absolute;
                    left: -7px;
                    top: 6.5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.card_payment_wrapper {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  min-height: 100vh;
  background: rgba(0,0,0,.9);
  padding: 50px 15px;
  @media screen and (max-width: 700px), (max-height: 500px) {
    flex-wrap: wrap;
    flex-direction: column;
  }

  &.active {
    display: block!important;
    margin: 0!important;
  }

  .card-form {
    max-width: 470px;
    margin: auto;
    width: 100%;

    &__inner {
      background: #fff;
      border-radius: 10px;
      padding: 35px;
    }

    &__button {
      width: 100%;
      height: 55px;
      background: #2364d2;
      border: none;
      border-radius: 5px;
      font-size: 19px;
      font-weight: bold;
      box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.2);
      color: #fff;
      margin-top: 20px;
      cursor: pointer;

      &.back {
        background: transparent;
        color: #939393;
        box-shadow: inherit;
        border: 1px solid#939393;
      }
    }

    &__error {
      color: #df1b41;
      margin-top: 8px;
      font-size: 12px;
      font-weight: bold;
    }
  }
}
</style>
