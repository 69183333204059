<template>
  <div class="footer">
    <ul class="links">
      <li><a href="https://lp-maider.studio.site//">会社概要</a></li>
      <li><a href="https://lp-maider.studio.site/terms">利用規約</a></li>
      <li><a href="https://lp-maider.studio.site/tokusho">特定商取引法に基づく表記</a></li>
    </ul>
    <p class="copyright"><small>©2023 OLYMPUS. KK,</small></p>
  </div>
</template>

<script>
</script>

<style lang='scss'>
.footer {
  margin-bottom: 0;
  padding: 20px 0;
  text-align: center;
  width: 100%;
  background: #EEEEEE;
  color: #ababab;

  li {
    list-style: none;
  }

  .links {
    margin: 30px 0px 20px 0px;

    li {
      display: inline-block;
      font-size: 13px;
      padding: 0 5px;

      a {
        color: #ababab;
        text-decoration-line: underline;
        text-decoration-style: initial;
        text-decoration-color: initial;
      }
    }

    li:after {
      content: '|';
      margin-left: 15px;
    }

    li:last-child:after {
      content: '';
    }
  }
}
</style>
