import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { title: 'プラン加入' },
    component: HomeView
  },
  {
    path: '/signup',
    name: 'signup',
    meta: { title: '新規登録' },
    component: function () {
      return import('../views/SignupView.vue')
    }
  },
  {
    path: '/mail_verify',
    name: 'mail_verify',
    meta: { title: 'メールアドレス認証' },
    component: function () {
      return import('../views/MailVerifyView.vue')
    }
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: 'ログイン' },
    component: function () {
      return import('../views/LoginView.vue')
    }
  },
  {
    path: '/callback',
    name: 'callback',
    meta: { title: 'ログイン' },
    component: function () {
      return import('../views/CallbackView.vue')
    }
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { title: 'ログアウト' },
    component: function () {
      return import('../views/LogoutView.vue')
    }
  },
  {
    path: '/mobile_closed',
    name: 'mobile_closed',
    component: function () {
      return import('../views/MobileClosedView.vue')
    }
  },
  {
    path: '/complete',
    name: 'complete',
    component: function () {
      return import('../views/CompleteView.vue')
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    meta: { title: '存在しないページ' },
    component: function () {
      return import('../views/NotFoundView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
