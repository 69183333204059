import { createStore } from 'vuex'

export default createStore({
  state: {
    auth: {
      isAuth: false,
      token: null,
      user: null
    }
  },
  getters: {
    getUser (state) {
      return state.auth.user
    },
    getisAuth (state) {
      return state.auth.isAuth
    },
    getAuthToken (state) {
      return state.auth.token
    }
  },
  mutations: {
    saveToken (state, token) {
      state.auth.token = token
    },
    removeToken (state) {
      state.auth.token = null
    },
    saveUser (state, user) {
      state.auth.user = user
    },
    setisAuth (state, boolean) {
      state.auth.isAuth = boolean
    }
  },
  actions: {
    saveToken ({ commit }, token) {
      commit('saveToken', token)
    },
    saveUser ({ commit }, user) {
      commit('saveUser', user)
    },
    setisAuth ({ commit }, boolean) {
      commit('setisAuth', boolean)
    }
  },
  modules: {
  }
})
