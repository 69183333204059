<template>
  <TopContainer/>
  <router-view/>
  <BottomContainer/>
</template>

<script>
import TopContainer from '@/components/TopContainer.vue'
import BottomContainer from '@/components/BottomContainer.vue'

export default {
  components: {
    TopContainer,
    BottomContainer
  },
  deta () {
    return {
      user: null,
      token: null,
      auth0_isLoading: this.$auth0.isLoading,
      routeInstance: this.$route
    }
  },
  watch: {
    '$route' (routeInstance) {
      this.createTitleDesc(routeInstance)
    }
  },
  async mounted () {
    if (window.matchMedia && window.matchMedia('(max-device-width: 1349px)').matches) {
      this.$router.push('mobile_closed')
    }

    await this.$auth0.getAccessTokenSilently({ detailedResponse: true, scope: 'read:current_user' })
      .then(async r => {
        this.token = r.id_token
        this.user = this.$auth0.user
        this.$store.dispatch('saveToken', this.token)
        this.$store.dispatch('saveUser', this.user)
        this.$store.dispatch('setisAuth', true)
      })

    if (this.$store.getters.getisAuth) {
      this.checkCustomerID()
    }
  },
  methods: {
    createTitleDesc: function (routeInstance) {
      console.log(routeInstance)
      if (routeInstance.meta.title) {
        const setTitle = 'maider - ' + routeInstance.meta.title
        document.title = setTitle
      } else {
        document.title = 'maider - プラン加入'
      }
    },
    checkCustomerID () {
      if (!this.$store.getters.getUser['https://maider.jp/customerID']) {
        const data = {
          email: this.$store.getters.getUser.email,
          nickname: this.$store.getters.getUser.name
        }

        this.axios.post('https://api-001.maider.jp/user/stripe/customer', data, { headers: { Authorization: 'Bearer ' + this.$store.getters.getAuthToken } })
          .then(response => {
            console.error(response)
          })
          .catch(err => {
            console.error(err)
          })
      }
    }
  }
}
</script>

<style lang="scss">
.home {
  flex: 1;
}
</style>
